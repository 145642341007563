import React from "react";
import LandingTemplate from "@src/components/partials/landing/LandingTemplate";
import LandingForm from "@src/components/partials/landing/LandingForm";
import IntroducingImg from "./introducing.png";

export default function K12() {
  const props = {
    image: IntroducingImg,
    title: "All of K-12. All in one app.",
    body: "K-12 communication made simple, reliable, and private. Seamlessly connect classrooms, schools, and families across your entire district in one easy-to-use platform, with top-tier security and oversight. 100% free for district partners.",
    cta: "Get your district activity report",
    Form: (
      <LandingForm
        submitLabel="Get your district activity report"
        title="See how many K-12 schools in your district already use ClassDojo"
        name="k12"
        version="1"
      />
    ),
    formTitle: "See how many K-12 schools in your district already use ClassDojo",
  };

  return <LandingTemplate {...props} />;
}
